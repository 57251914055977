import React from 'react'
import './Footer.css'
import mcicon from '../../images/mc-logo-white.png'
const Footer = () => {
  return (
    <footer>
      <div className="div-footer-logo">
        <img
          alt="Matheus Catossi Logo"
          className="footer-logo"
          src={mcicon}
        />
      </div>
      {/* <p className="copyright">
        Feito por{' '} 👨🏻‍💻 {' '}
        Matheus Catossi
      </p> */}
    </footer>
  )
}

export default Footer
