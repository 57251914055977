const skills = [
  {
    "id": 0,
    "skillName": "Node.js",
    "amount": "80"
  },
  {
    "id": 1,
    "skillName": "React.js",
    "amount": "70"
  },
  {
    "id": 2,
    "skillName": "Javascript",
    "amount": "70"
  },
  {
    "id": 3,
    "skillName": "Angular",
    "amount": "70"
  },
  {
    "id": 4,
    "skillName": "C#",
    "amount": "60"
  },
  {
    "id": 5,
    "skillName": "DevEx",
    "amount": "60"
  },
  {
    "id": 6,
    "skillName": "MongoDB",
    "amount": "60"
  },
  {
    "id": 7,
    "skillName": "SQL Server",
    "amount": "60"
  },
  {
    "id": 8,
    "skillName": "Java",
    "amount": "60"
  },
  {
    "id": 9,
    "skillName": "AWS",
    "amount": "50"
  },
  {
    "id": 10,
    "skillName": "MySQL",
    "amount": "50"
  },
  {
    "id": 11,
    "skillName": "Outsystems",
    "amount": "40"
  },
  {
    "id": 12,
    "skillName": "Azure",
    "amount": "40"
  },
  {
    "id": 13,
    "skillName": "IBM Cloud",
    "amount": "40"
  },
  {
    "id": 14,
    "skillName": "Python",
    "amount": "20"
  }
];

export default skills;