import React, { useState } from 'react'
import './Navbar.css'
import { Link } from 'react-scroll'
import MobileNav from '../mobileNav/MobileNav'
import mcicon from '../../images/mc-icon.png'

const Navbar = () => {
  const [mobilenavVisible, setMobilenavVisible] = useState(false)
  const [hamburgerClass, setHamburgerClass] = useState('')
  const toggleMobilenavVisible = () => {
    setMobilenavVisible(!mobilenavVisible)
    if (hamburgerClass === '') {
      setHamburgerClass('open')
    } else {
      setHamburgerClass('')
    }
  }
  return (
    <div className="navbar">

      <img
        alt="Matheus Catossi Logo"
        className="logo"
        src={mcicon}
      />
      <div className="navlinks">
        <div className="navlink-wrapper">


          <Link to="home" spy={true} smooth={true} duration={500}>
            INÍCIO
          </Link>
        </div>
        <div className="navlink-wrapper">
          <Link to="sobre" spy={true} smooth={true} duration={500}>
            SOBRE
          </Link>
        </div>
        <div className="navlink-wrapper">
          <Link to="experiência" spy={true} smooth={true} duration={500}>
            EXPERIÊNCIAS
          </Link>
        </div>
        {/* <div className="navlink-wrapper">
          <Link to="projects" spy={true} smooth={true} duration={500}>
            PROJECTS
          </Link>
        </div> */}
        <div className="navlink-wrapper">
          <Link to="contato" spy={true} smooth={true} duration={500}>
            CONTATO
          </Link>
        </div>
      </div>
      <div className="hamburger">
        <div
          id="hamburger-icon"
          className={hamburgerClass}
          onClick={toggleMobilenavVisible}
        >
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <MobileNav
        toggleMobilenavVisible={toggleMobilenavVisible}
        mobilenavVisible={mobilenavVisible}
      />
    </div>
  )
}

export default Navbar
