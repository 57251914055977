import React from 'react'
import './About.css'
import { Fade } from 'react-reveal'
import Section from '../section/Section'
import Skills from '../skills/Skills'

const About = () => {
  return (
    <Section title="Sobre">
      <div className="about-content">
        <Fade duration={1000}>
          <div className="about-text">



            <h2>Quem sou eu?</h2>
            <p>
              Olá, meu nome é Matheus Catossi!{' '}
              <span role="img" aria-label="lightning">
                😄
              </span>{' '}
            </p>
            <p>
              <span role="img" aria-label="lightning">
                ⚡
              </span>{' '}
              Sou um Desenvolvedor de Software com uma trajetória que começou aos 15 anos e que abrange diversos segmentos do mercado, incluindo investimentos, call centers, serviços de campo, chatbots, entre outros. Tenho uma abordagem focada em absorver o máximo de informações dos mercados nos quais atuo, bem como das tecnologias envolvidas, para sempre me aprimorar e entregar resultados excepcionais. Minhas experiências me proporcionaram amplo conhecimento na criação de produtos digitais, e estou constantemente me adaptando às tecnologias emergentes.
            </p>
            <p>
              <span role="img" aria-label="lightning">
                ⚡
              </span>{' '}
              Em termos de tecnologias, já trabalhei com linguagens como Java, C# e Javascript, o que me confere uma sólida compreensão de contextos bastante distintos. Além disso, possuo expertise em plataformas de Cloud, como AWS e IBM. No que diz respeito a bancos de dados, acumulei experiência tanto com sistemas relacionais (SQL Server, PostgreSQL, etc.) quanto com bancos de dados não relacionais (MongoDB e IBM Cloudant).
            </p>
            <p>
              <span role="img" aria-label="lightning">
                ⚡
              </span>{' '}
              Alguns dos frameworks com os quais tive experiência incluem ReactJS, Node.js e Angular.
            </p>
            <p>
              <span role="img" aria-label="lightning">
                ⚡
              </span>{' '}
              Em relação às minhas abordagens de trabalho, já participei tanto da criação de produtos do zero quanto da integração em projetos em andamento, seguindo conceitos ágeis que enfatizam entregas incrementais, bem como abordagens não incrementais. Ao longo da minha carreira, desempenhei diversas funções no âmbito do desenvolvimento de software, incluindo tanto liderança de equipe quanto tarefas mais operacionais. Para mim, o aspecto mais crucial é estar profundamente envolvido no processo e contribuir para fornecer o máximo valor possível, garantindo que a entrega final atenda às expectativas.
            </p>
            <div className="location-wrapper">
              <svg
                className="octicon octicon-location"
                viewBox="0 0 16 16"
                version="1.1"
                width="16"
                height="16"
                aria-hidden="true"
              >
                <path
                  fill="white"
                  fillRule="evenodd"
                  d="M11.536 3.464a5 5 0 010 7.072L8 14.07l-3.536-3.535a5 5 0 117.072-7.072v.001zm1.06 8.132a6.5 6.5 0 10-9.192 0l3.535 3.536a1.5 1.5 0 002.122 0l3.535-3.536zM8 9a2 2 0 100-4 2 2 0 000 4z"
                ></path>
              </svg>
              <p>São Paulo, SP - Brasil</p>
            </div>
            <h2>Conheça a Maia 🤖</h2>

            <video width="100%" src="maia.mp4" controls autoPlay playsInline>
              <p>
                Your browser doesn't support HTML video. Here is a
                <a href="maia.mp4">link to the video</a> instead.
              </p>
            </video>

            {/* <p>
              Since 2015 - I've spent my time seeking and learning new
              technologies and forms of digital expression. This has led to me
              working on some amazing world-class{' '}
              <Link
                className="textLink"
                to="projects"
                spy={true}
                smooth={true}
                duration={500}
              >
                projects
              </Link>
              , worked at some{' '}
              <Link
                className="textLink"
                to="experience"
                spy={true}
                smooth={true}
                duration={500}
              >
                amazing places
              </Link>
              , and worked with some great people.
            </p> */}
            <br />

          </div>
        </Fade>
        <Skills />
      </div>
    </Section>
  )
}

export default About
