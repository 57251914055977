import React, { useEffect, useState } from 'react'
import './App.css'
import Home from './components/home/Home'
import About from './components/about/About'
import Experience from './components/experience/Experience'
import Contact from './components/contact/Contact'
import Footer from './components/footer/Footer'
import TopButton from './components/topButton/TopButton'

function App() {

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (loading === true) {
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  }, [loading]);

  return (
    <>
      <div className="App">
        <Home loading={loading} />
        {loading === false ?
          <>
            <About />
            <Experience />
            <Contact />
            
            <Footer />
            <TopButton />
          </>
          :
          <>
            <br/>
          </>
        }
      </div>
    </>
  )
}

export default App
