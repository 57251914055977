import React, { useRef } from 'react'
import IsVisible from 'react-is-visible'
import { Fade } from 'react-reveal'
import { useContainerDimensions } from '../../hooks'
import skills from '../../data/skills'

const Skills = () => {
  const skillsWrapper = useRef()
  const { width } = useContainerDimensions(skillsWrapper)

  function winnerText(text) {
    return (<p>
      <span role="img" aria-label="lightning">
        🏆
      </span>{' '}
      {text}
    </p>
    )
  }

  return (
    <Fade duration={1000}>
      <div style={{ position: 'relative', width: '100%', maxWidth: 600 }}>
        <IsVisible once>
          {(isVisibleSkillsWrapper) => (
            <div
              className="skills-wrapper"
              style={
                isVisibleSkillsWrapper
                  ? {
                    transition: '1s opacity ease-in-out',
                    transform: `translateX(0)`,
                    opacity: 1,
                  }
                  : {}
              }
            >
              <h2>Conhecimentos</h2>
              <ul className="skills" ref={skillsWrapper}>
                {skills.map((skills) => {
                  return (
                    <li className="skill-bar-wrapper" key={skills.skillName}>
                      <div
                        className="skill-bar"
                        style={
                          isVisibleSkillsWrapper
                            ? {
                              transition: `${1 + skills.id / 10
                                }s width ease-in-out`,
                              width: width * (skills.amount / 100),
                            }
                            : {
                              width: 1,
                            }
                        }
                      ></div>
                      <div className="skill-name">{skills.skillName}</div>
                    </li>
                  )
                })}
              </ul>

              <br />
              <h2>Premiações</h2>
              <ul className="skills" ref={skillsWrapper}>
                {winnerText('1º lugar - Health Hackathon- Secretária Municipal da Saúde')}
                {winnerText('1º lugar - Deloitte Hackathon - Deloitte)} IBM e TechData.')}
                {winnerText('1º lugar - 2nd CIAB Febraban Hackathon')}
                {winnerText('1º lugar - Movile Hack - Movile')}
                {winnerText('1º lugar - JK Iguatemi Hackathon- Iguatemi')}
                {winnerText('1º lugar - Loggathon Hackathon - Loggi')}
                {winnerText('1º lugar - Raízen Hackathon- Raízen')}
                {winnerText('1º lugar - Inova ZL 2019 Hackathon - Galpão de Cultura')}
                {winnerText('1º lugar - Openthon 2021 - CMS Brasil ')}
                {winnerText('2º lugar - Nasa Space Apps Challenge Hackathon 2017 - NASA')}
                {winnerText('2º lugar - PMI Hackathon- PMI)} Stefanini e FIAP')}
                {winnerText('2º lugar - 3rd CIAB Febraban Hackathon')}
                {winnerText('3º lugar - Hack in Sampa Hackathon - Câmara Municipal de SP')}
                {winnerText('3º lugar - Porto Hackathon)} BTP:CODE - BTP')}
                {winnerText('3º lugar - #OCampoNaCampus Hackathon - John Deere')}
                {winnerText('3º lugar - Globo Hackathon - Globo')}
                {winnerText('Finalista - The Code Force Santander Hackathon')}
              </ul>
            </div>
          )}
        </IsVisible>

      </div>
    </Fade>
  )
}

export default Skills
