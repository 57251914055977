import React, { useEffect,  useState } from 'react'
import './Home.css'
import Fade from 'react-reveal/Fade'
import { Bounce } from 'react-reveal'
import { Link } from 'react-scroll'
import Particles from 'react-particles-js'
import Typewriter from 'typewriter-effect'
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle'
import Navbar from '../navbar/Navbar'
import config from '../../config'
import mcicon from '../../images/mc-icon.png'

import linkedin from '../../images/social/linkedin.png'
import { FillingBottle } from 'react-cssfx-loading'

const Home = ({
  loading
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
  }, [loading]);

  return (

    <div className="home-wrapper" title="Home">
      <div className="home">
        {loading && !imageLoaded ?
          <>
            <FillingBottle color="#0069ff" width="200px" height="200px" duration="3s" />
            <br />
            <Typewriter
              options={{
                strings: [
                  'Bem vindo(a)',
                ],
                autoStart: true,
                loop: true,
                deleteSpeed: 10,
                cursor: '',
                delay: 50,
              }}
            />
          </>
          :
          <>

            <Particles className="particles" params={config.particles} />

            <div className={`greeting${!imageLoaded ? ' hide' : ''}`}>

              <Fade bottom distance="100px">

                {/* <img
                  className="profile"
                  alt="m.catossi profile"
                  src={profile}
                  onLoad={() => setImageLoaded(true)}
                /> */}

                <img
                  className="profile"
                  alt="m.catossi logo"
                  src={mcicon}
                  onLoad={() => setImageLoaded(true)}
                />

                <h1 className="greeting-text">
                  Olá, bem vindo(a)! {' '}
                  <span className="wave-emoji" role="img" aria-label="waving hand">
                    👋
                  </span>
                </h1>
                <hr />
                <h1 className="greeting-text">
                  <Typewriter
                    options={{
                      strings: [
                        'apaixonado por desenvolvimento de software <3  ',
                        'adoro fazer negócios! ',
                        'crio experiências tecnológicas únicas!!! ',
                      ],
                      autoStart: true,
                      loop: true,
                      deleteSpeed: 10,
                      cursor: '<',
                      delay: 100,
                    }}
                  />
                </h1>
                <Bounce cascade>
                  <div className="links">
                    <a
                      href="https://www.linkedin.com/in/matheuscatossi/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={linkedin} alt="Linkedin Logo" width="50px" />
                    </a>
                  </div>
                </Bounce>
                <div className="scroll-down">
                  <Link
                    activeClass="active"
                    to="sobre"
                    spy={true}
                    smooth={true}
                    offset={-63}
                    duration={500}
                  >
                    <ArrowDropDownCircleIcon
                      fontSize="large"
                      style={{ pointerEvents: 'fill', cursor: 'pointer' }}
                    />
                  </Link>
                </div>
              </Fade>
            </div>
            <Navbar />
          </>
        }

      </div>
    </div>

  )
}

export default Home
