const experience = [
  {
    "id": 0,
    "link": "https://www.konecta-group.com/pt",
    "company": "Uranet Projetos e Sistemas",
    "title": "Software Developer",
    "image": "uranet-logo.jpeg",
    "dateFrom": "Março 2016",
    "dateTo": "Maio 2017",
    "info": [
      "Atividade 1",
      "Atividade 2"
    ],
    "stack": ["PHP", "jQuery", "Javascript", "Linux"],
    "logoheight": 80,
    "colourPrimary": "linear-gradient(to bottom, #e6c250, #1a1a1a)",
    "colourSecondary": "linear-gradient(to bottom, #e6c250, #1a1a1a)"
  },
  {
    "id": 1,
    "link": "https://ideiasdefuturo.com/",
    "company": "Ideias de Futuro",
    "title": "Technical Mentee",
    "image": "ideas-de-futuro-logo.jpeg",
    "dateFrom": "Agosto 2016",
    "dateTo": "Agosto 2017",
    "info": [
      "Atividade 1",
      "Atividade 2"
    ],
    "stack": ["App Inventor"],
    "logoheight": 80,
    "colourPrimary": "linear-gradient(to bottom, #3d90b5, #1a1a1a)",
    "colourSecondary": "linear-gradient(to bottom, #3d90b5, #1a1a1a)"
  },
  {
    "id": 2,
    "link": "https://www.linkedin.com/company/horizon-four/about/",
    "company": "Horizon Four",
    "title": "Software Developer",
    "image": "horizon-four-logo.jpeg",
    "dateFrom": "Abril 2017",
    "dateTo": "Junho 2017",
    "info": [
      "Atividade 1",
      "Atividade 2"
    ],
    "stack": ["Node.js", "Python", "Javascript", "React"],
    "logoheight": 80,
    "colourPrimary": "linear-gradient(to bottom, #04fbdc, #1a1a1a)",
    "colourSecondary": "linear-gradient(to bottom, #04fbdc, #1a1a1a)"
  },
  {
    "id": 3,
    "link": "https://www.ibm.com/",
    "company": "IBM",
    "title": "Lead Software Developer",
    "image": "ibm-logo.png",
    "dateFrom": "Junho 2017",
    "dateTo": "Junho 2021",
    "info": [
      "Atividade 1",
      "Atividade 2"
    ],
    "stack": ["Java", "Angular", "IBM Cloud", "Node.js"],
    "logowidth": 80,
    "colourPrimary": "linear-gradient(to bottom, #0043ce, #1a1a1a)",
    "colourSecondary": "linear-gradient(to bottom, #0043ce, #1a1a1a)"
  },
  {
    "id": 4,
    "link": "https://whg.com.br/",
    "company": "WHG",
    "title": "Sofware Developer",
    "image": "whg-logo.jpeg",
    "dateFrom": "Junho 2021",
    "dateTo": "Julho 2023",
   "info": [
      "Atividade 1",
      "Atividade 2"
    ],
    "stack": ["C#", "Angular", "Azure", "Devex"],
    "logowidth": 80,
    "colourPrimary": "linear-gradient(to bottom, #3f697c, #1a1a1a)",
    "colourSecondary": "linear-gradient(to bottom, #3f697c, #1a1a1a)"
  },
  {
    "id": 5,
    "link": "https://www.cactuscan.com",
    "company": "Resolv",
    "title": "Sofware Developer",
    "image": "resolv-logo.jpeg",
    "dateFrom": "Janeiro 2023",
    "dateTo": "Novembro 2023",
   "info": [
      "Atividade 1",
      "Atividade 2"
    ],
    "stack": ["React", "Node.js", "AWS", "Next"],
    "logowidth": 80,
    "colourPrimary": "linear-gradient(to bottom, #1c2c58, #1a1a1a)",
    "colourSecondary": "linear-gradient(to bottom, #1c2c58, #1a1a1a)"
  }
];

export default experience;